var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "misc-wrapper" },
    [
      _c(
        "b-link",
        { staticClass: "brand-logo" },
        [
          _c("vuexy-logo"),
          _c("h2", { staticClass: "brand-text text-primary ml-1" }, [
            _vm._v(" Vuexy ")
          ])
        ],
        1
      ),
      _c("div", { staticClass: "misc-inner p-2 p-sm-3" }, [
        _c(
          "div",
          { staticClass: "w-100 text-center" },
          [
            _c("h2", { staticClass: "mb-1" }, [
              _vm._v(" We are launching soon 🚀 ")
            ]),
            _c("p", { staticClass: "mb-3" }, [
              _vm._v(
                " We're creating something awesome. Please subscribe to get notified when it's ready! "
              )
            ]),
            _c(
              "b-form",
              {
                staticClass: "row justify-content-center m-0 mb-2",
                attrs: { inline: "" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c("b-form-input", {
                  staticClass: "col-12 col-md-5 mb-1 mr-md-2",
                  attrs: {
                    id: "notify-email",
                    type: "text",
                    placeholder: "john@example.com"
                  }
                }),
                _c(
                  "b-button",
                  {
                    staticClass: "mb-1 btn-sm-block",
                    attrs: { variant: "primary", type: "submit" }
                  },
                  [_vm._v(" Notify ")]
                )
              ],
              1
            ),
            _c("b-img", {
              attrs: { fluid: "", src: _vm.imgUrl, alt: "Coming soon page" }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }